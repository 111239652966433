// import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken, getXToken, setXToken, removeXToken, setUserInfo, setTokenObjective, removeUserInfo } from '@/utils/auth'
import { login, wxLogin, getInfo, dingLogin } from '@/api/auth/auth'

const getDefaultState = () => {
  return {
    token: getToken(),
    xtoken: getXToken(),
    name: '',
    avatar: '',
    info: null
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state: any) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state: any, token: any) => {
    state.token = token
  },
  SET_XTOKEN: (state: any, xtoken: any) => {
    state.xtoken = xtoken
  },
  SET_NAME: (state: any, name: any) => {
    state.name = name
  },
  SET_AVATAR: (state: any, avatar: any) => {
    state.avatar = avatar
  },
  SET_INFO: (state: any, info: any) => {
    state.info = info
  }
}

const actions = {

  // user login
  login({ commit }: any, code: any) {
    return new Promise((resolve, reject) => {
      wxLogin({ code: code }).then((response: any) => {
        if (response.success) {
          commit('SET_TOKEN', response.data.token)
          setToken(response.data.token)

          commit('SET_XTOKEN', response.data.xtoken)
          setXToken(response.data.xToken)
          setTokenObjective(response)
        }
        resolve({})
      }).catch((error: any) => {
        reject(error)
      })
    })
  },
  // user login
  accountLogin({ commit }: any, userInfo: any) {
    const { account, password, userType, validateCode, validateCodeId } = userInfo
    return new Promise((resolve, reject) => {
      login({ account: account.trim(), password: password, userType: userType, validateCode: validateCode, validateCodeId: validateCodeId}).then((response: any) => {
        if (response.success) {
          commit('SET_TOKEN', response.data.token)
          setToken(response.data.token)

          commit('SET_XTOKEN', response.data.xtoken)
          setXToken(response.data.xToken)
          setTokenObjective(response)
        }
        resolve(response)
      }).catch((error: any) => {
        reject(error)
      })
    })
  },
  dingLogin({ commit }: any, code: any) {
    return new Promise((resolve, reject) => {
      dingLogin({ code: code, isH5App: 1 }).then((response: any) => {
        if (response.success) {
          commit('SET_TOKEN', response.data.token)
          setToken(response.data.token)

          commit('SET_XTOKEN', response.data.xtoken)
          setXToken(response.data.xToken)
          setTokenObjective(response)
        }
        resolve(response)
      }).catch((error: any) => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit }: any) {
    return new Promise((resolve, reject) => {
      getInfo().then((response: any) => {
        if (response.success) {
          commit('SET_INFO', response.data)
          commit('SET_NAME', response.data.empInfo.name)
          commit('SET_AVATAR', response.data.avatar)

          setUserInfo(response.data)
        }
        resolve(response)
      }).catch((error: any) => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }: any) {
    return new Promise((resolve) => {
      removeToken()
      removeXToken()
      removeUserInfo()
      commit('RESET_STATE')
      resolve({})
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
