import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue'
import { getTips } from '@/api/formTips/index'
import { FORMTIPS } from '@/utils/tip-config'


export default /*@__PURE__*/_defineComponent({
  __name: 'XForm',
  props: {
  modelValue: Object,
  tip: String
},
  emits: ['update:modelValue', 'postData', 'setTipData'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props

const emit = __emit

const form: any = ref(null)

const getFormTips = () => {
  if (props.tip) {
    const tipMarks: any = FORMTIPS[props.tip]
    const postData = Object.values(tipMarks)
    getTips({ codeList: postData }).then((res: any) => {
      if (res.success && res.data) {
        const tipData: any = {}
        for (var i in tipMarks) {
          const data = res.data.find((r: any) => { return r.code === tipMarks[i] })
          tipData[i] = ({ tip: i, code: tipMarks[i], text: data?.text ?? '' })
        }
        emit('update:modelValue', tipData)
      }
    })
  }
}

getFormTips()

const handleSubmit = () => {
  form.value.submit()
}

const onSubmit = () => {
  emit('postData')
}

const validate = () => {
  return new Promise((resolve) => {
    resolve(form.value.validate())
  })
}

__expose({
  handleSubmit,
  validate
})


return (_ctx: any,_cache: any) => {
  const _component_van_form = _resolveComponent("van-form")!

  return (_openBlock(), _createBlock(_component_van_form, _mergeProps({
    ref_key: "form",
    ref: form,
    onSubmit: onSubmit,
    "validate-trigger": ['onChange', 'onBlur', 'onSubmit'],
    "submit-on-enter": false
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16))
}
}

})